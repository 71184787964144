
@keyframes scroll-link{
    0%{ bottom:50px; }
    50%{ bottom:40px; }
    100%{ bottom:50px; }
}

body.proyecto{
    width: 100%;
    height: 100%;
    background: #FFF;

    .section-row{

        &.section-1{

            width:100%;
            position: relative;
            padding:190px 0 120px;
            @media(max-width: 800px){
                padding:140px 0 90px;
            }

            h3{
                font-family:$font-family-title;
                font-size:150px;
                line-height:150px;
                color:#004C50;
                margin-bottom:90px;
                @media(max-width: 800px){
                    font-size:60px;
                    line-height:60px;
                    margin-bottom: 60px;
                }
            }

            p{
                font-family:$font-family-title;
                font-size:35px;
                line-height:40px;
                color:#004C50;
                margin-bottom:90px;
                @media(max-width: 800px){
                    font-size:30px;
                    line-height:30px;
                    margin-bottom: 60px;
                }
                span{
                    display: block;
                    color:#F8B280;
                }
            }

        }

        &.section-full-image{

            width:100%;
            height: 110vh;
            background-repeat:no-repeat;
            background-position: center center;
            background-size: cover;

            @media(max-width: 800px){

            }

        }

        &.section-plano{

            width:100%;
            padding:100px 0 75px;
            @media(max-width: 991px){
                //padding:16px 0 16px;
            }

            h4{
                font-family:$font-family-text;
                font-size:16px;
                line-height:30px;
                color:#004C50;
                margin-bottom: 12px;
                @media(max-width:991px){
                    font-size:14px;
                    line-height:20px;
                    margin-bottom: 6px;
                }
            }

            h5{
                font-family:$font-family-title;
                font-size:60px;
                line-height:60px;
                color:#004C50;
                margin-bottom:50px;
                @media(max-width:991px){
                    font-size:30px;
                    line-height:30px;
                    margin-bottom:30px;
                }
                span{
                    color:#F8B280;
                    display: block;
                }
            }

            p{
                font-family:$font-family-text;
                font-size:16px;
                line-height:25px;
                color:#004C50;
                margin-bottom: 44px;
            }

            .inner-plano{
                padding:73px 0 0;
                border-top:1px solid #004C50;
                //border-bottom:1px solid #004C50;
                min-height: 700px;
                @media(max-width: 991px){
                    padding:14px 0 0;
                }

                .svg{
                    width:100%;
                    max-width: 700px;
                    min-height: 640px;
                    float:left;
                    position:relative;

                    .brujula{
                        position:absolute;
                        top:30px;
                        right:0px;
                        background: url(../images/planos/ic_brujula.png) center center no-repeat;
                        background-size: contain;
                        width: 29px;
                        height: 28px;
                    }

                    .referencia{
                        position:absolute;
                        bottom:38px;
                        right:0px;
                        background: url(../images/planos/ic_escala.png) center center no-repeat;
                        width: 74px;
                        height: 15px;
                        @media(max-width:500px){
                            display:none;
                        }
                    }
                }
            }

            &.plano-special{

                svg.special{
                    max-width:300px;
                    margin-top:60px;
                    @media(max-width:991px){
                        margin-top:10px;
                        max-width:none;
                    }
                }

                .inner-plano{
                    border-top:0px;
                    padding-top:0px;

                    h4{
                        padding-bottom: 12px;
                        margin-bottom: 73px;
                        border-bottom:1px solid #004C50;
                        @media(max-width:991px){
                            padding-bottom: 6px;
                            margin-bottom: 14px;
                        }
                    }

                }

            }

            &.plano-subsuelo{

                h5{
                    position: absolute;
                }

                .svg{
                    max-width: 100%;
                    svg{
                        @media(max-width:991px){
                            height:520px !important;
                        }
                        @media(max-width:600px){
                            height:420px !important;
                        }
                    }
                    .brujula{
                        top:50px;
                    }
                    .referencia{
                        display:none;
                        background: url(../images/planos/plano-subsuelo-escala.svg) center center no-repeat;
                    }
                }

                .inner-plano{
                    @media(max-width:991px){
                        min-height:auto !important;
                    }
                }
                .svg{
                    @media(max-width:991px){
                        min-height:auto !important;
                    }
                }

            }

            &.plano-special{
                .svg{
                    .brujula{
                        top:10px;
                    }
                    .referencia{
                        bottom:4px;
                        background: url(../images/planos/plano-subsuelo-escala.svg) center center no-repeat;
                    }
                }

            }

        }

    }

}

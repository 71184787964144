
@keyframes scroll-link{
    0%{ bottom:50px; }
    50%{ bottom:40px; }
    100%{ bottom:50px; }
}

body.concepto{
    width: 100%;
    height: 100%;
    background: #FFF;

    .section-row{
        //height:100vh;

        &.section-1{

            width:100%;
            position: relative;
            padding:190px 0 100px;
            @media(max-width: 767px){
                padding:140px 0 140px;
                margin-bottom:100px;
            }

            p{
                font-family:$font-family-text;
                font-size:16px;
                line-height:25px;
                color:#004C50;
                padding:70px 0 0;
                @media(max-width: 800px){
                    padding:0px 0 10px;
                }
            }
            h3{
                font-family:$font-family-title;
                font-size:150px;
                line-height:150px;
                color:#004C50;
                margin-bottom:90px;
                @media(max-width: 800px){
                    font-size:60px;
                    line-height:60px;
                    margin-bottom: 60px;
                }
            }
            h4{
                font-family:$font-family-title;
                font-size:40px;
                line-height:40px;
                color:#004C50;
                margin-bottom:90px;
                @media(max-width: 800px){
                    font-size:30px;
                    line-height:30px;
                    margin-bottom: 60px;
                }
                span{
                    color:#F8B280;
                    display: block;
                }
            }

            a.arrow-down{
                position:absolute;
                bottom:50px;
                left:50%;
                margin-left:-15px;
                background:url(../images/home/ic_arrow-down-green.png) center center no-repeat;
                width: 31px;
                height: 16px;
                animation: scroll-link 1s infinite 1s;
                &:hover{
                    animation-play-state: paused;
                }
            }

        }

        &.section-concepto{
            padding:100px 0;
            @media(max-width: 767px){
                padding:0 0 59px;
            }
            .image{
                width:100%;
                height:600px;
                background-size: contain;
                background-position: right center;
                background-repeat: no-repeat;
                @media(max-width: 767px){
                    height:300px;
                    background-size: cover;
                    background-position: center center;
                    margin-top:0px;
                }
            }

            .content-fluid{
                padding:50px 0;
                border-top:1px solid #004C50;
                //border-bottom:1px solid #004C50;
                @media(max-width: 767px){
                    border-top:0px;
                    padding:0px 0 0;
                }
            }

            h4{
                font-family:$font-family-title;
                font-size:60px;
                line-height:60px;
                color:#004C50;
                margin:23px 0 48px;
                @media(max-width: 767px){
                    margin-top:0px;
                    font-size:30px;
                    line-height:30px;
                    background:url('../images/general/ic_acc-open.png') right center no-repeat;
                    background-size:17px 9px;
                    padding:24px 0;
                    margin:0 9px;
                    cursor:pointer;
                    br{
                        display: none;
                    }

                }
                span{
                    font-family:$font-family-text;
                    font-size:16px;
                    line-height:25px;
                }
            }
            .inner-concepto{
                transition: all 0.5s;
                @media(max-width: 767px){
                    height: 0;
                    opacity: 0;
                    overflow: hidden;
                    padding: 0 9px;
                }
            }
            .concepto-info.active{
                h4{
                    @media(max-width: 767px){
                        background-image:url('../images/general/ic_acc-close.png');
                    }
                }
                .inner-concepto{
                    padding-bottom: 45px;
                    @media(max-width: 767px){
                        height: auto;
                        opacity: 1;
                    }
                }
            }

            p{
                font-family:$font-family-text;
                font-size:16px;
                line-height:25px;
                color:#004C50;
                margin:0 0 42px;
            }
            table{
                width: 100%;
                tr{
                    padding:0;
                }
                td{
                    width:50%;
                    p{
                        margin:0;
                        font-size:12px;
                        line-height:19px;
                    }

                }
            }

        }

    }

}

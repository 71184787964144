
@keyframes scroll-link{
    0%{ bottom:50px; }
    50%{ bottom:40px; }
    100%{ bottom:50px; }
}

body.faq{
    width: 100%;
    height: 100%;
    background: #FFF;

    .section-row{
        //height:100vh;

        &.section-1{

            width:100%;
            position: relative;
            padding:190px 0 0px;
            @media(max-width: 800px){
                padding:100px 0 0px;
            }
            h3{
                font-family:$font-family-title;
                font-size:160px;
                line-height:140px;
                color:#004C50;
                margin-bottom:100px;
                @media(max-width: 800px){
                    font-size:60px;
                    line-height:60px;
                    margin-bottom: 70px;
                }
                @media(max-width: 800px){
                    font-size:60px;
                    line-height:60px;
                    margin-bottom: 20px;
                }
                @media(max-width: 500px){
                    font-size:50px;
                    line-height:50px;
                    margin-bottom: 0px;
                }
            }

            .border-bottom{
                border-bottom:1px solid #004C50 !important;
            }

            span{
                display: block;
                padding:27px 0 26px;
                font-family:$font-family-text;
                font-size:16px;
                line-height:25px;
                color:#004C50;
                position: relative;
                margin-left:-15px;
                i{
                    color:#F8B280;
                    font-style: normal;
                }
            }
            /*
            .row-top{
                padding:25px 0 0;
                span{
                    padding:17px 0 16px;
                }
            }

            .row-bottom{
                margin:0 0 90px;
            }

            @media(max-width: 767px){

                .col-sm-5 {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 41.66667%;
                    flex: 0 0 41.66667%;
                    max-width: 41.66667%;
                }
                .col-sm-7 {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 58.33333%;
                    flex: 0 0 58.33333%;
                    max-width: 58.33333%;
                }

            }
            */

            .border-bottom{
                border-bottom:1px solid #004C50 !important;
            }

            .row-top{
                margin-left:0;
                margin-right:0;
                padding:25px 0 0;
                span{
                    padding:17px 0 16px;
                }
            }
            .row-bottom{
                margin-left:0;
                margin-right:0;
                margin-bottom:90px;
                .border-bottom{
                    &.col-sm-7{
                        @media(max-width: 575px){
                            span{
                                padding-top:0px;
                            }
                        }
                    }
                }
            }

            .second-faq{
                .row-bottom{
                    .border-bottom{
                        &.col-sm-5.col-md-2{
                            @media(max-width: 575px){
                                border-bottom:0px !important;
                                background:url('../images/general/ic_acc-open.png') right center no-repeat;
                                background-size:17px 9px;
                                cursor:pointer;
                                span{
                                    padding: 21px 0 21px;
                                }
                                & + .col-sm-7{
                                    height:0;
                                    transition: all 0.5s;
                                    overflow: hidden;
                                    span{
                                        opacity: 0;
                                        transition: all 0.2s;
                                        padding: 0px;
                                    }
                                }
                                &.active{
                                    & + .col-sm-7{
                                        height: auto;
                                        span{
                                            opacity: 1;
                                            padding: 0 0 21px;
                                        }
                                    }
                                    background-image:url('../images/general/ic_acc-close.png');
                                }
                            }
                        }

                    }
                }
            }

            .first-faq{
                @media(max-width: 575px){
                    .row-bottom{
                        margin-bottom: 60px;
                        .border-bottom{
                            border-bottom:0px !important;
                            span{
                                padding:22px 0 20px;
                            }
                        }
                    }
                    .col-sm-5.col-md-2.border-bottom{
                        display:none;
                    }
                }
            }



        }

    }

}

@keyframes scroll-link{
    0%{ bottom:50px; }
    50%{ bottom:40px; }
    100%{ bottom:50px; }
}

body.landing .parallax-mirror{
    @media(max-width: 800px){
        //display: none;
    }
}

body.landing {
    width: 100%;
    height: 100%;
    background: #FFF;

    .section-row{
        //height:100vh;


        &.section-1{
            position: relative;
            //background:url('../images/home/bg_section-1.jpg') center center no-repeat;
            width:100%;
            display: table;
            line-height: 98vh;
            @media(max-width: 800px){
                background:url('../images/home/bg_section-1.jpg') center center no-repeat;
                //background-attachment: fixed;
                background-size: cover;
                line-height:normal;
                display: block;
                height: 90vh;
                .col-sm-12{
                    height: 100%;
                }
            }

            .mobile-show{
                display:none;
                font-family:$font-family-text;
                font-size:12px;
                line-height:15px;
                color:#FFF;
                margin-bottom: 30px;
                @media(max-width: 800px){
                    display: block;
                    margin-top:40px;
                }
            }

            h2{
                vertical-align: middle;
                display: inline-block;
                @media(max-width: 800px){
                    vertical-align: top;
                    margin-top:10px;
                }
            }
            svg.logo-big{
                fill: #FFF;

                @media(max-width: 800px){
                    height: 180px !important;
                    width: 350px;
                    & > g{
                        transform: scale(0.6);
                    }
                }

                @media(max-width: 600px){
                    height: 106px !important;
                    width: 230px;
                    & > g{
                        transform: scale(0.4);
                    }
                }

            }
            a.arrow-down{
                position:absolute;
                bottom:50px;
                left:50%;
                margin-left:-15px;
                background:url(../images/home/ic_arrow-down.png) center center no-repeat;
                width: 31px;
                height: 16px;
                animation: scroll-link 1s infinite 1s;
                &:hover{
                    animation-play-state: paused;
                }
            }
        }


        &.section-2{

            width:100%;
            padding:290px 0 190px;
            @media(max-width: 800px){
                padding:140px 0 90px;
            }

            p{
                font-family:$font-family-text;
                font-size:16px;
                line-height:25px;
                color:#004C50;
            }
            .mobile-hide{
                display:block;
                @media(max-width: 800px){
                    display: none;
                }
            }
            h3{
                font-family:$font-family-title;
                font-size:60px;
                line-height:60px;
                color:#004C50;
                margin-bottom:90px;
                @media(max-width: 800px){
                    font-size:30px;
                    line-height:30px;
                    margin-bottom: 60px;
                }
            }
            svg.logo{
                height: 52px!important;
                width: 380px;
                fill: #F8B280;
                & > g{
                    transform: scale(1.03);
                }
                @media(max-width: 800px){
                    height: 24px!important;
                    width: 190px;
                    & > g{
                        transform: scale(0.5);
                    }
                }
            }

            .col-text{
                -webkit-column-count: 2;
                column-count: 2;
                @media(max-width: 800px){
                    -webkit-column-count: 1;
                    column-count: 1;
                }
            }

        }


        &.section-3{

            width:100%;
            padding:0;
            //display: none;
            .video{
                background:#839191;
                width: 100%;
                height: 90vh;

                iframe {
                    width: 100%;
                    height: 90vh;
                }
            }

            //TEST
            .video {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
                max-width: 100%;
                height: auto;
            }
            .video iframe,
            .video object,
            .video embed {
                position: absolute;
                top: 0%;
                width: 100%;
                height: 100%;
            }

        }


        &.section-4{
            padding:190px 0 120px;
            @media(max-width: 800px){
                padding:120px 0 90px;
            }

            h4{
                font-family:$font-family-text;
                font-size:16px;
                line-height:25px;
                color:#004C50;
                margin:0 0 47px;
            }

            .table-cols{

                width:100%;

                //display:flex;
                @media(max-width: 1200px){
                    display:block;
                }

                .table-cols-item{
                    border-left:1px solid #bfd2d3;
                    padding:0 29px;
                    min-height: 350px;
                    @media(max-width: 650px){
                        border-left:none;
                        border-bottom:1px solid #bfd2d3;
                        padding:0 0px;

                        &:last-child {
                            border:none;
                        }
                    }

                    &:first-child {
                        //flex: 1 1 auto;
                        //min-width:350px;
                    }
                    svg{
                        fill:#004C50;
                        margin:52px 0 32px;
                        @media(max-width: 800px){
                            margin:42px 0 32px;
                        }
                    }
                    h6{
                        font-family:$font-family-title;
                        font-size:30px;
                        line-height:30px;
                        color:#F8B280;
                        padding-bottom: 15px;
                        //height: 123px;
                        @media(max-width: 800px){
                            height: auto;
                            padding-bottom: 22px;
                        }
                    }
                    p{
                        font-family:$font-family-text;
                        font-size:16px;
                        line-height:25px;
                        color:#004C50;
                        margin-bottom:36px;
                        @media(max-width: 800px){
                            margin-bottom: 40px;
                        }
                    }

                    @media(max-width: 1200px){
                        width:50%;
                        float: left;
                    }

                    @media(max-width: 800px){
                        width:100%;
                        padding: 0 30px;
                        &:first-child {
                            min-width:0px;
                        }
                    }

                }

            }

        }



        &.section-5{

            .swiper-container{

                .swiper-wrapper{

                    .swiper-slide{
                        height:100vh; 
                        max-height: 800px;
                        width:100%;
                        background-size: cover;
                        background-position: center center;
                        background-repeat: no-repeat;
                        @media(max-width: 1200px){
                            height:650px;
                        }
                        @media(max-width: 800px){
                            height:440px;
                        }
                        &-inner{
                            width:100%;
                            height: 100%;
                            background-size: cover;
                            background-position: center center;
                            background-repeat: no-repeat;
                        }
                        .vista360{
                            height: 100%;
                        }
                    }

                }

                .swiper-pagination{
                    bottom:50px;
                    .swiper-pagination-bullet{
                        width:15px;
                        height: 15px;
                        border:1px solid #FFF;
                        opacity: 1;
                        background-color: transparent;
                        margin: 0 8px;
                        &-active{
                            background-color: #FFF;
                        }
                    }

                }

                .swiper-button-next, .swiper-button-prev{
                    width: 51px;
                    height: 101px;
                    top:47%;
                    @media(max-width: 800px){
                        display: none;
                    }
                }
                .swiper-button-next{
                    background:url('../images/general/ic_next.png') center center no-repeat;
                    right: 40px;
                }
                .swiper-button-prev{
                    background:url('../images/general/ic_prev.png') center center no-repeat;
                    left: 40px;
                }

            }
        }



        &.section-6{
            position: relative;
            width:100%;
            padding:190px 0 120px;
            @media(max-width: 767px){
                padding:100px 0 0;
                .content-fluid{
                    width:100%;
                    overflow: hidden;
                    padding-bottom:90px;
                }
            }

            h3{
                color:#004C50;
                font-family:$font-family-title;
                font-size:30px;
                line-height:30px;
                margin-top:45px;
                margin-bottom: 10px;
                @media(max-width: 767px){
                    padding:0 15px;
                }
            }

            .swiper-container#vistas360texto{
                @media(max-width: 767px){
                    //overflow: visible;
                }
                .swiper-wrapper{
                    .swiper-slide{
                        height: 416px;
                        width:100%;
                        &-inner{
                            padding:96px 0 0;
                            p{
                                color:#004C50;
                                font-family:$font-family-text;
                                font-size:16px;
                                line-height:25px;
                            }
                        }
                        @media(max-width: 767px){

                            height: 170px;
                            &-inner{
                                padding:26px 15px 0;
                            }

                        }
                    }
                }

                & + .swiper-pagination{
                    bottom:0px;
                    text-align: left;
                    height: 18px;
                    .swiper-pagination-bullet{
                        width:15px;
                        height: 15px;
                        border:1px solid #004C50;
                        opacity: 1;
                        background-color: transparent;
                        margin: 0 8px;
                        &-active{
                            background-color: #004C50;
                        }
                    }
                    @media(max-width: 767px){
                        bottom:-340px;
                        width: 100%;
                        text-align: center;
                        z-index: 20;
                        left: 0;
                    }
                }

            }

            .swiper-container#vistas360{
                @media(max-width: 767px){

                }
                .swiper-wrapper{
                    .swiper-slide{
                        width:100%;
                        &-inner{

                            .vista360{
                                width:100%;
                                height:500px;
                                background:#839191;
                                @media(max-width: 767px){
                                    height:300px;
                                    iframe{
                                        height:300px !important;
                                    }
                                }
                            }

                        }
                    }
                }

            }

        }


        &.section-7{

            width:100%;
            padding:190px 0 120px;
            @media(max-width: 800px){
                padding:100px 0 90px;
            }

            .col-text {
                margin-bottom:120px;
                -webkit-column-count: 2;
                column-count: 2;
                @media(max-width: 800px){
                    -webkit-column-count: 1;
                    column-count: 1;
                }
            }
            p{
                font-family:$font-family-text;
                font-size:16px;
                line-height:25px;
                color:#004C50;
                margin: auto;
                padding: 0 30px 0 0;
            }
            h3{
                font-family:$font-family-title;
                font-size:60px;
                line-height:60px;
                color:#004C50;
                margin-bottom:90px;
                @media(max-width: 800px){
                    font-size:30px;
                    line-height:30px;
                    margin-bottom: 60px;
                }
            }
            svg.logo{
                height: 52px!important;
                width: 380px;
                fill: #F8B280;
                & > g{
                    transform: scale(1.03);
                }
                @media(max-width: 800px){
                    height: 24px!important;
                    width: 190px;
                    & > g{
                        transform: scale(0.5);
                    }
                }
            }

            .brands{

                span{
                    display: block;
                    font-family:$font-family-text;
                    font-size:11px;
                    line-height:15px;
                    letter-spacing: 0.9px;
                    color:#004C50;
                }
                .img{
                    line-height: 140px;
                    & > *{
                        vertical-align: middle;
                    }
                    @media(max-width: 800px){
                        line-height: 58px;
                    }
                    @media(max-width: 350px){
                        & > *{
                            width:100%;
                        }
                    }
                }
                .col-6 > div{
                    border-left:1px solid #bfd2d3;
                    padding:0 23px;
                    @media(max-width: 800px){
                        border-left:2px solid #bfd2d3;
                        padding:0 12px;
                    }
                }
                .desarrollo{}
                .administracion{}

            }

        }


        &.section-9{

            width:100%;
            padding:190px 0 120px;
            height: 110vh;
            background-repeat:no-repeat;
            background-position: center center;
            background-size: cover;

            @media(max-width: 800px){

            }

        }

    }

}

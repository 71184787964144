
@keyframes scroll-link{
    0%{ bottom:50px; }
    50%{ bottom:40px; }
    100%{ bottom:50px; }
}

body.terminaciones{
    width: 100%;
    height: 100%;
    background: #FFF;

    .section-row{
        
        &.section-img{
            padding:0px;
            img{
                width: 100%;
            }
        }

        &.section-1{

            width:100%;
            position: relative;
            padding:140px 0 50px;
            @media(max-width: 800px){
                padding:100px 0 80px;
            }
            p{
                font-family:$font-family-text;
                font-size:16px;
                line-height:25px;
                color:#004C50;
                padding:70px 0 0;
                @media(max-width: 800px){
                    padding:0px 0 10px;
                }
            }
            h3{
                font-family:$font-family-title;
                font-size:150px;
                line-height:150px;
                color:#004C50;
                margin-bottom:90px;
                @media(max-width: 800px){
                    font-size:60px;
                    line-height:60px;
                    margin-bottom: 60px;
                }
            }
            h4{
                font-family:$font-family-title;
                font-size:40px;
                line-height:40px;
                color:#004C50;
                margin-bottom:90px;
                @media(max-width: 800px){
                    font-size:30px;
                    line-height:30px;
                    margin-bottom: 60px;
                }
                span{
                    color:#F8B280;
                    display: block;
                }
            }
        }

        &.section-2{

            width:100%;
            position: relative;
            padding:40px 0 0px;
            @media(max-width: 800px){
                padding:20px 0 0px;
            }

            .row-top, .row-bottom{

                span{
                    display: block;
                    padding:27px 0 26px;
                    font-family:$font-family-text;
                    font-size:16px;
                    line-height:25px;
                    color:#004C50;
                    position: relative;
                    margin-left:-15px;
                }

            }

            .border-bottom{
                border-bottom:1px solid #004C50 !important;
            }

            .row-top{
                margin-left:0;
                margin-right:0;
                padding:25px 0 0;
                span{
                    padding:17px 0 16px;
                }
            }
            .row-bottom{
                margin-left:0;
                margin-right:0;
                margin-bottom:90px;
                .border-bottom{
                    &.col-sm-5.col-md-2{
                        @media(max-width: 575px){
                            border-bottom:0px !important;
                            background:url('../images/general/ic_acc-open.png') right center no-repeat;
                            background-size:17px 9px;
                            cursor:pointer;
                            span{
                                padding: 21px 0 21px;
                            }
                            & + .col-sm-7{
                                height:0;
                                transition: all 0.5s;
                                overflow: hidden;
                                span{
                                    opacity: 0;
                                    transition: all 0.2s;
                                    padding: 0px;
                                }
                            }
                            &.active{
                                & + .col-sm-7{
                                    height: auto;
                                    span{
                                        opacity: 1;
                                        padding: 0 0 21px;
                                    }
                                }
                                background-image:url('../images/general/ic_acc-close.png');
                            }
                        }
                    }
                    &.col-sm-7{
                        @media(max-width: 575px){
                            span{
                                padding-top:0px;
                            }
                        }
                    }
                }
            }

        }

    }

}

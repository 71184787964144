
@keyframes scroll-link{
    0%{ bottom:50px; }
    50%{ bottom:40px; }
    100%{ bottom:50px; }
}

body.contacto{
    width: 100%;
    height: 100%;
    background: #FFF;

    .section-row{

        &.section-img{
            padding:0px;
            img{
                width: 100%;
            }
        }

        &.section-middle{

            width:100%;
            position: relative;
            padding:190px 0 150px;
            @media(max-width: 800px){
                padding:140px 0 100px;
            }
            .content-fluid {
                width: calc(100% - 80px);
            }
            h4{
                font-family:$font-family-title;
                font-size:40px;
                line-height:40px;
                color:#004C50;
                margin-bottom:0px;
                @media(max-width: 800px){
                    font-size:30px;
                    line-height:30px;
                }
                span{
                    color:#F8B280;
                    display: block;
                }
            }

        }

        &.section-1{

            width:100%;
            position: relative;
            padding:170px 0 90px;
            @media(max-width: 991px){
                padding:140px 0 40px;
            }

            h3{
                font-family:$font-family-title;
                font-size:80px;
                line-height:100px;
                color:#004C50;
                margin-bottom:40px;
                @media(max-width: 800px){
                    font-size:64px;
                    line-height:64px;
                    margin-bottom: 60px;
                }
            }
            h4{
                font-family:$font-family-title;
                font-size:40px;
                line-height:40px;
                color:#004C50;
                margin-bottom:40px;
                @media(max-width: 800px){
                    font-size:30px;
                    line-height:30px;
                    margin-bottom: 40px;
                }
                span{
                    color:#F8B280;
                    display: inline-block;
                }
            }

            p{
                font-family:$font-family-title;
                font-size:35px;
                line-height:40px;
                color:#004C50;
                @media(max-width: 800px){
                    font-size:30px;
                    line-height:30px;
                }
            }

            a.arrow-down{
                position:absolute;
                bottom:50px;
                left:50%;
                margin-left:-15px;
                background:url(../images/home/ic_arrow-down-green.png) center center no-repeat;
                width: 31px;
                height: 16px;
                animation: scroll-link 1s infinite 1s;
                &:hover{
                    animation-play-state: paused;
                }
            }

            .inmobiliaria{
                span{
                    font-size:10px;
                    line-height:15px;
                    font-family:$font-family-text;
                    display:block;
                    color:#004C50;
                    padding-bottom:12px;
                    @media(max-width: 991px){
                        letter-spacing: 1px;
                    }
                }
                p{
                    font-size:16px;
                    line-height:25px;
                    font-family:$font-family-text;
                    padding-top:19px;
                }
                svg{
                    @media(max-width: 991px){
                        margin-bottom:15px;
                    }
                }
            }

            .contact-text-content{
                padding-right:100px;
                @media(max-width: 991px){
                    padding-right:0px;
                }
            }

            .contact-form-content{
                
                margin-top:75px;
                @media(max-width: 991px){
                    margin-top:0px;
                }

                input, textarea{
                    font-size:16px;
                    line-height:25px;
                    font-family:$font-family-text;
                    color:#004C50;
                    padding-left:10px;
                    padding-top:10px;
                    padding-bottom:15px;
                    @media(max-width: 991px){
                        color:#054D51;
                    }
                }
                input:focus, textarea:focus{
                    outline: none;
                }
                label.error{
                    position:absolute;
                    bottom: 10px;
                    left: 16px;
                    color: red;
                    font-size: 11px;
                    line-height:15px;
                    font-family:$font-family-text;
                }

                input{
                    width:100%;
                    border:0;
                    border-bottom:1px solid #004C50;
                    margin-bottom:35px;
                    @media(max-width: 991px){
                        border-bottom:1px solid #004c507a;
                    }
                }

                textarea{
                    width:100%;
                    border:0;
                    border:1px solid #004C50;
                    border-radius:3px;
                    padding-top:6px;
                    height:160px;
                    margin-bottom:35px;
                    @media(max-width: 991px){
                        border:1px solid #004c507a;
                        margin-bottom:0px;
                    }
                }

                p{
                    font-size:16px;
                    line-height:25px;
                    font-family:$font-family-text;
                    color:#004C50;
                    float:left;
                    margin: 10px 0 0;
                    @media(max-width: 991px){
                        float:none;
                        margin: 10px 0 0;
                    }
                }

                button{
                    border:1px solid #004C50;
                    border-radius:50px;
                    font-size:16px;
                    line-height:25px;
                    font-family:$font-family-text;
                    color:#004C50;
                    background-color: transparent;
                    padding:0px 77px 4px;
                    height: 50px;
                    float:right;
                    @media(max-width: 991px){
                        float:none;
                        margin-top:25px;
                        margin-bottom:50px;
                    }
                    transition: all 0.1s;
                    &:hover{
                        border-color:#F8B280;
                        color:#FFF;
                        background:#F8B280;
                    }
                }

            }

        }


    }

}
::-webkit-input-placeholder{color: #004C50; opacity: 1;}
::-moz-placeholder{color: #004C50; opacity: 1;}
:-moz-placeholder{color: #004C50; opacity: 1;} 
:-ms-input-placeholder{color: #004C50; opacity: 1;}
::placeholder{color: #004C50; opacity: 1;}
@media(max-width: 991px){
    ::-webkit-input-placeholder{color: #004C50; opacity: 0.5;}
    ::-moz-placeholder{color: #004C50; opacity: 0.5;}
    :-moz-placeholder{color: #004C50; opacity: 0.5;} 
    :-ms-input-placeholder{color: #004C50; opacity: 0.5;}
    ::placeholder{color: #004C50; opacity: 0.5;}
}
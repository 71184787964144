
/*IMPORT FONTS*/

@import url('https://fonts.googleapis.com/css?family=Montserrat');

/*PLAIN REGULAR*/
@font-face {
    font-family: 'Plain-Regular';
    src: url('../fonts/Plain-Regular.eot') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Plain-Regular';
    src:url('../fonts/Plain-Regular.otf')  format('opentype'), url('../fonts/Plain-Regular.ttf')  format('truetype'), url('../fonts/Plain-Regular.svg#Plain-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/*PLAIN LIGHT*/

@font-face {
    font-family: 'Plain-Light';
    src: url('../fonts/Plain-Light.eot') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Plain-Light';
    src:  url('../fonts/Plain-Light.otf')  format('opentype'), url('../fonts/Plain-Light.ttf')  format('truetype'), url('../fonts/Plain-Light.svg#Plain-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

/*PLATFORM BOLD*/
@font-face {
    font-family: 'Platform-Bold';
    src: url('../fonts/Platform-Bold.eot') format('embedded-opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Platform-Bold';
    src:  url('../fonts/Platform-Bold.otf')  format('opentype'), url('../fonts/Platform-Bold.ttf')  format('truetype'), url('../fonts/Platform-Bold.svg#Platform-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}



/*VARIABLES*/

$font-family-text: 'Plain-Light', sans-serif;
$font-family-text2: 'Plain-Regular', sans-serif;
$font-family-title: 'Platform-Bold', sans-serif;

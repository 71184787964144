
@keyframes scroll-link{
    0%{ bottom:50px; }
    50%{ bottom:40px; }
    100%{ bottom:50px; }
}

body.unidades{
    width: 100%;
    height: 100%;
    background: #FFF;

    .section-row{

        &.section-img{
            padding:0px;
            img{
                width: 100%;
            }
        }

        &.section-middle{

            width:100%;
            position: relative;
            padding:190px 0 150px;
            @media(max-width: 800px){
                padding:140px 0 100px;
            }
            .content-fluid {
                width: calc(100% - 80px);
            }
            h4{
                font-family:$font-family-title;
                font-size:40px;
                line-height:40px;
                color:#004C50;
                margin-bottom:0px;
                @media(max-width: 800px){
                    font-size:30px;
                    line-height:30px;
                }
                span{
                    color:#F8B280;
                    display: block;
                }
            }

        }

        &.section-1{

            width:100%;
            position: relative;
            padding:190px 0 120px;
            @media(max-width: 800px){
                padding:140px 0 90px;
            }

            h3{
                font-family:$font-family-title;
                font-size:150px;
                line-height:150px;
                color:#004C50;
                margin-bottom:90px;
                @media(max-width: 800px){
                    font-size:60px;
                    line-height:60px;
                    margin-bottom: 60px;
                }
            }
            h4{
                font-family:$font-family-title;
                font-size:40px;
                line-height:40px;
                color:#004C50;
                margin-bottom:90px;
                @media(max-width: 800px){
                    font-size:30px;
                    line-height:30px;
                    margin-bottom: 60px;
                }
                span{
                    color:#F8B280;
                    display: block;
                }
            }

            p{
                font-family:$font-family-title;
                font-size:35px;
                line-height:40px;
                color:#004C50;
                margin-bottom:90px;
                @media(max-width: 800px){
                    font-size:30px;
                    line-height:30px;
                    margin-bottom: 60px;
                }
            }

            a.arrow-down{
                position:absolute;
                bottom:50px;
                left:50%;
                margin-left:-15px;
                background:url(../images/home/ic_arrow-down-green.png) center center no-repeat;
                width: 31px;
                height: 16px;
                animation: scroll-link 1s infinite 1s;
                &:hover{
                    animation-play-state: paused;
                }
            }

        }

        &.section-unidad{

            padding:75px 0;
            position: relative;

            & > .content-fluid{
                position: relative;
                width:calc(100% - 80px);
            }

            p{
                font-family:$font-family-text;
                color:#004C50;
                margin:0;
            }

            .description{
                border-bottom:1px solid #004C50;
                padding:0 0 50px;
                h5{
                    font-family:$font-family-title;
                    font-size:22px;
                    line-height:20px;
                    color:#004C50;
                }

                p{
                    font-family:$font-family-text;
                    font-size:16px;
                    line-height:20px;
                    color:#004C50;
                }

            }

            .ambiente-tipo{

                padding:20px 0 25px;
                border-bottom:1px solid #004C50;

                &.padding{padding:25px 0 25px;height:204px;}

                svg.num{
                    display: inline-block;
                    fill:#004C50;
                    vertical-align: bottom;
                    margin-right: 12px;
                }
                h6{
                    font-family:$font-family-text;
                    font-size:12px;
                    line-height:20px;
                    color:#004C50;
                    margin-bottom: 25px;
                }
                p{
                    font-family:$font-family-title;
                    font-size:33px;
                    line-height:22px;
                    color:#004C50;
                }

                svg.mini{
                    float: right;
                }
            }

            .ua-pisos{
                padding: 20px 0 10px;
                border-bottom:1px solid #004C50;
                span{
                    display: block;
                    font-family:$font-family-text;
                    font-size:12px;
                    line-height:20px;
                    color:#004C50;
                    margin-bottom: 20px;
                }
                p{
                    font-family:$font-family-text;
                    font-size:9px;
                    line-height:12px;
                    color:#004C50;
                }
                svg{
                    margin-top:5px;
                }
            }

            .nav-unidades{
                padding:5px 0 0;
                .col-6, .col-3{
                    cursor:pointer;
                    opacity:0.30 !important;
                    &.active{
                        cursor:default;
                        opacity:1 !important;
                    }
                }
                span{
                    font-family:$font-family-text;
                    font-size:20px;
                    line-height:20px;
                    color:#004C50;
                    position: absolute;
                    top:10px;
                    left:15px;
                }
                .hidden{
                    display: none;
                }

            }

            .superficies{
                padding:20px 0;
                border-bottom:1px solid #004C50;
                h6{
                    font-family:$font-family-text;
                    font-size:32px;
                    line-height:42px;
                    margin-bottom: 16px;
                    color:#004C50;
                }
                p{
                    font-family:$font-family-text;
                    font-size:12px;
                    line-height:20px;
                    color:#004C50;
                    span{
                        float: right;
                        width: 50%;
                    }
                }
            }


            //SVG
            .svg{
                width:100%;
                max-width: 700px;
                min-height: 640px;
                float:right;
                position:relative;
                margin:0 auto;
                @media(max-width: 991px){
                    min-height: 500px;
                    svg{
                        width:100%;
                        height:500px;
                        image{
                            height:500px;
                        }
                    }
                }
                @media(max-width: 600px){
                    svg{
                        width:92%;
                    }
                }

                &.d-md-block{
                    float:none;
                }

                .zoom{
                    position:absolute;
                    bottom: 25px;
                    right: 0px;
                    background: url(../images/unidades/ic_zoom.svg) center center no-repeat;
                    background-size: 30px 60px;
                    width: 30px;
                    height: 60px;
                    & > div{
                        cursor: pointer;
                        width: 30px;
                        height: 30px;
                        float: left;
                    }
                    @media(max-width: 991px){
                        display: none;
                    }
                }

                .brujula{
                    position:absolute;
                    top:30px;
                    right:15px;
                    background: url(../images/unidades/ic_brujula.png) center center no-repeat;
                    width: 28px;
                    height: 28px;
                    background-size: contain;
                    @media(max-width: 991px){
                        right:9px;
                    }
                }

                .referencia{
                    display: none;
                    position:absolute;
                    bottom:20px;
                    left:0;
                    background: url(../images/unidades/ic_ref2.svg) center center no-repeat;
                    background-size: 88px 15px;
                    width: 88px;
                    height: 15px;
                    &.small{
                        background-image: url(../images/unidades/ic_ref.svg);
                        background-size: 65px 15px;
                        width: 65px;
                    }
                }
            }


            .swiper-slide{
                overflow: hidden;
                background: #FFF;
            }



        }

    }

}

header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    .inner-header{
        width:1200px;
        margin: 0 auto;
        @media(max-width: 1200px){
            width:calc(100% - 40px);
        }
    }

    .midnightInner{
        padding:35px 0;
        @media(max-width: 800px){

        }
    }

    h1.logo{
        float:left;
        a{
            display: block;
            svg{
                display: block;
                fill:#004C50;
                height:auto;
                width:auto;
                opacity: 0;
            }
        }

    }

    nav.navbar{
        float:right;
        ul{
            float:right;
            li{
                float:left;
                list-style:none;
                padding:3px 0 0 40px;
                a{
                    font-family:$font-family-text;
                    font-size:16px;
                    color:#004C50;
                }
            }
        }
        @media(max-width: 991px){
            display:none;
        }
    }

    .midnightHeader.banner {
        h1.logo a svg{
            fill:#FFF;
            opacity: 0;
        }
        nav.navbar ul li a{
            color:#FFF;
        }
        .mobile-btn{
            background-image: url('../images/general/ic_menu-open.png');
        }
    }
    .midnightHeader.white {
        h1.logo a svg{
            fill:#FFF;
            opacity: 1;
        }
        nav.navbar ul li a{
            color:#FFF;
        }
        .mobile-btn{
            background-image: url('../images/general/ic_menu-open.png');
        }
    }
    .midnightHeader.green {
        h1.logo a svg{
            fill:#004C50;
            opacity: 1;
        }
        nav.navbar ul li a{
            color:#004C50;
        }
        .mobile-btn{
            background-image: url('../images/general/ic_menu-open-g.png');
        }
    }

}

//MOBILE
.mobile-btn{
    display: none;
    background-image: url('../images/general/ic_menu-open-g.png');
    cursor:pointer;
    @media(max-width: 991px){
        display: block;
        position:absolute;
        top:20px;
        right:20px;
        width: 50px;
        height: 50px;
        background-size: contain;
    }
    &.active{
        background-image: url('../images/general/ic_menu-close.png');
    }
}

.header-mobile{
    display:none;

    @media(max-width: 991px){
        &.active{
            display:block;
            .mobile-btn{
                background-image: url('../images/general/ic_menu-close.png');
            }
        }
        position: fixed;
        top:0;
        left:0;
        width:100%;
        height:100vh;
        background: #F8B280;
        z-index: 100;
        padding:0 20px;

        h1.logo{
            margin-top:98px;
            a{
                display: block;
                svg{
                    display: block;
                    fill:#FFF;
                    height:104px;
                    width:230px;
                    & > g{
                        transform: scale(0.4);
                    }
                }
            }

        }

        nav.navbar{
            ul{
                margin:0;
                padding:0;
                li{
                    list-style:none;
                    a{
                        font-family:$font-family-title;
                        font-size:64px;
                        line-height:56px;
                        color:#FFF;
                        span, br{
                            display: none;
                            @media(max-width: 500px){
                                display: inline;
                            }
                        }

                        &:hover{
                            text-decoration: none;
                        }
                    }
                }
            }
        }

    }


}

body.landing .header-mobile h1.logo a svg{ fill:#004C50; }
body.ubicacion .header-mobile nav.navbar ul li a.ubicacion,
body.planos .header-mobile nav.navbar ul li a.planos,
body.unidades .header-mobile nav.navbar ul li a.unidades,
body.terminaciones .header-mobile nav.navbar ul li a.terminaciones,
body.contacto .header-mobile nav.navbar ul li a.contacto,
body.concepto .header-mobile nav.navbar ul li a.concepto{color:#004C50;}

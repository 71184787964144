footer{

    .footer-top{

        background-color: #004c50;
        padding:68px 0 60px;
        margin-top:-1px;
        @media(max-width: 991px){
            padding:30px 0 30px;
        }

        .hidden-lg.col-sm-12{
            display: none;
            @media(max-width: 991px){
                display: block;
            }
        }
        .hidden-sm{
            @media(max-width: 991px){
                display: none;
            }
        }

        h1.logo{

            a{

                svg{
                    fill:#FFF;
                }
            }
            @media(max-width: 991px){
                margin-bottom:30px;
            }
        }

        nav.navbar{
            ul{
                padding: 0 0 30px;
                margin: 0;
                @media(max-width: 991px){
                    padding: 44px 0 10px;
                }
                li{
                    float: left;
                    width: 50%;
                    list-style: none;
                    a{
                        font-family:$font-family-text;
                        font-size:16px;
                        line-height:25px;
                        color:#FFF;
                        @media(max-width: 991px){
                            font-size:14px;
                            line-height:30px;
                        }
                    }
                }
            }
        }

        a.button{
            display: inline-block;
            font-family:$font-family-text;
            font-size:16px;
            line-height:50px;
            color:#004C50;
            padding: 0 25px;
            border-radius: 50px;
            background-color: #FFF;
            float: right;
            @media(max-width: 1000px){
                float: none;
            }
            &:hover{
                background-color:#F8B280;
                color:#FFF;
                text-decoration: none;
            }
        }

    }

    .footer-bottom{

        background-color: #003f42;
        padding:20px 0;

        p{
            text-align: center;
            font-size:12px;
            line-height: 14px;
            font-family:$font-family-text;
            margin: 0;
            color:#99b2b3;
            @media(max-width: 800px){
                line-height: 16px;
            }
            @media(max-width: 400px){
                font-size:11px;
            }
            a{
                color:#FFF;
            }
            span{
                display: inline;
                @media(max-width: 800px){
                    display: none;
                }
            }
            br{
                display: none;
                @media(max-width: 800px){
                    display: block;
                }
            }

        }
    }

}


@keyframes scroll-link{
    0%{ bottom:50px; }
    50%{ bottom:40px; }
    100%{ bottom:50px; }
}

body.ubicacion{
    width: 100%;
    height: 100%;
    background: #FFF;

    .section-row{
        //height:100vh;

        &.section-1{

            width:100%;
            position: relative;
            padding:190px 0 100px;
            @media(max-width: 800px){
                padding:140px 0 90px;
            }

            p{
                font-family:$font-family-text;
                font-size:16px;
                line-height:25px;
                color:#004C50;
                padding:70px 0 0;
                @media(max-width: 800px){
                    padding:0px 0 10px;
                }
            }
            h3{
                font-family:$font-family-title;
                font-size:150px;
                line-height:150px;
                color:#004C50;
                margin-bottom:90px;
                @media(max-width: 800px){
                    font-size:60px;
                    line-height:60px;
                    margin-bottom: 60px;
                }
            }
            h4{
                font-family:$font-family-title;
                font-size:40px;
                line-height:40px;
                color:#004C50;
                margin-bottom:90px;
                @media(max-width: 800px){
                    font-size:30px;
                    line-height:30px;
                    margin-bottom: 60px;
                }
                span{
                    color:#F8B280;
                    display: block;
                }
            }

            a.arrow-down{
                position:absolute;
                bottom:50px;
                left:50%;
                margin-left:-15px;
                background:url(../images/home/ic_arrow-down-green.png) center center no-repeat;
                width: 31px;
                height: 16px;
                animation: scroll-link 1s infinite 1s;
                &:hover{
                    animation-play-state: paused;
                }
            }

        }

        &.section-mapa-svg{
            //height: 100vh;
            //background:url(../images/ubicacion/mapa.svg) center center no-repeat;
            //background-size: cover;
            img{width: 100%;height: auto;}
        }

        &.section-2{

            .swiper-container{

                .swiper-wrapper{

                    .swiper-slide{
                        height:100vh;
                        max-height: 900px;
                        width:100%;
                        background-size: cover;
                        background-position: center center;
                        background-repeat: no-repeat;
                        @media(max-width: 1200px){
                            height:600px;
                        }
                        @media(max-width: 800px){
                            height:400px;
                        }
                        &-inner{
                            width:100%;
                            height: 100%;
                            background-size: cover;
                            background-position: center center;
                            background-repeat: no-repeat;

                            h4{
                                font-family:$font-family-title;
                                font-size:60px;
                                line-height:60px;
                                color:#FFF;
                                position: relative;
                                top:200px;
                                @media(max-width: 1200px){
                                    left:90px;
                                    top:150px;
                                }
                                @media(max-width: 800px){
                                    font-size:30px;
                                    line-height:30px;
                                    top:100px;
                                    left:20px;
                                }
                                span{
                                    color:#F8B280;
                                    display: block;
                                }
                            }
                        }
                    }

                }

                .swiper-pagination{
                    bottom:50px;
                    .swiper-pagination-bullet{
                        width:15px;
                        height: 15px;
                        border:1px solid #FFF;
                        opacity: 1;
                        background-color: transparent;
                        margin: 0 8px;
                        &-active{
                            background-color: #FFF;
                        }
                    }

                }

                .swiper-button-next, .swiper-button-prev{
                    width: 51px;
                    height: 101px;
                    top:47%;
                    @media(max-width: 800px){
                        display: none;
                    }
                }
                .swiper-button-next{
                    background:url('../images/general/ic_next.png') center center no-repeat;
                    right: 40px;
                }
                .swiper-button-prev{
                    background:url('../images/general/ic_prev.png') center center no-repeat;
                    left: 40px;
                }

            }
        }

    }

}
